export default function setFocus(target) {
	if (!target || !target.value) {
		return
	}

	setTimeout(() => {
		target.select()
		try {
			target.setSelectionRange(0, 9999) // iOS
		} catch (error) {}
	}, 100)
}
